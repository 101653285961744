<template>
  <v-container>
    <h3>Elenco log</h3>
    <v-row>
      <v-col md="4">
        <v-text-field
          label="Email"
          v-model="email"
          type="text"
          class="form-control"
          name="email"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          label="File"
          v-model="file"
          type="text"
          class="form-control"
          name="file"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Dal / al"
              prepend-icon="fa-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="danger"
              @click="
                $refs.menu.save(null);
                menu = false;
                getDataFromApi();
              "
            >
              Cancella
            </v-btn>
            <v-btn text color="secondary" @click="menu = false">
              Annulla
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(dateRange);
                getDataFromApi();
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :options.sync="tableParams"
      :server-items-length="totalItems"
      :loading="tableLoading"
      :headers="headers"
      :items="logs"
      sort-by="creationDate"
      sort-desc
      dense
      @update:must-sort="getDataFromApi"
      @update:items-per-page="getDataFromApi"
      @update:page="getDataFromApi"
      @update:sort-by="getDataFromApi"
      @update:sort-desc="getDataFromApi"
      class="elevation-1"
    >
      <template v-slot:item.creationDate="{ item }">
        {{ formatDate(item.creationDate) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import dateHelpers from "../helpers/dates.helpers";

export default {
  data() {
    return {
      menu: false,
      headers: [
        { text: "Data", value: "creationDate" },
        { text: "Utente", value: "user" },
        { text: "File", value: "file" },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    email: _.debounce(async function (val) {
      await this.getDataFromApi();
    }, 500),
    file: _.debounce(async function (val) {
      await this.getDataFromApi();
    }, 500),
  },
  computed: {
    computedDateFormatted() {
      if (this.dateRange === null || this.dateRange.length === 0) return null;
      let value = "";

      if (this.dateRange[0])
        value = "dal " + dateHelpers.formatServerDate(this.dateRange[0]);

      if (this.dateRange[1])
        value += " al " + dateHelpers.formatServerDate(this.dateRange[1]);
      return value;
    },
    tableParams: {
      get() {
        return this.$store.state.logsView.tableParams;
      },
      set(value) {
        this.$store.commit("logsView/setTableParams", value);
      },
    },

    tableLoading: {
      get() {
        return this.$store.state.logsView.tableLoading;
      },
      set(value) {
        this.$store.commit("logsView/setTableLoading", value);
      },
    },

    email: {
      get() {
        return this.$store.state.logsView.email;
      },
      set(value) {
        this.$store.commit("logsView/setEmail", value);
      },
    },

    file: {
      get() {
        return this.$store.state.logsView.file;
      },
      set(value) {
        this.$store.commit("logsView/setFile", value);
      },
    },

    dateRange: {
      get() {
        return this.$store.state.logsView.dateRange;
      },
      set(value) {
        this.$store.commit("logsView/setDateRange", value);
      },
    },

    totalItems() {
      return this.$store.state.logsView.logs.totalItems;
    },
    logs() {
      return this.$store.getters["logsView/logs/list"];
    },
  },
  async mounted() {
    await this.getDataFromApi();
  },

  methods: {
    formatDate(date) {
      return dateHelpers.formatServerDate(date, true);
    },

    async getDataFromApi() {
      await this.$store.dispatch("logsView/loadTableData");
    },
  },
};
</script>

<style>
</style>
