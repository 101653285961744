var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h3',[_vm._v("Elenco log")]),_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{staticClass:"form-control",attrs:{"label":"Email","type":"text","name":"email","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{staticClass:"form-control",attrs:{"label":"File","type":"text","name":"file","hide-details":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateRange=$event},"update:return-value":function($event){_vm.dateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Dal / al","prepend-icon":"fa-calendar","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"danger"},on:{"click":function($event){_vm.$refs.menu.save(null);
              _vm.menu = false;
              _vm.getDataFromApi();}}},[_vm._v(" Cancella ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.save(_vm.dateRange);
              _vm.getDataFromApi();}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.tableParams,"server-items-length":_vm.totalItems,"loading":_vm.tableLoading,"headers":_vm.headers,"items":_vm.logs,"sort-by":"creationDate","sort-desc":"","dense":""},on:{"update:options":function($event){_vm.tableParams=$event},"update:must-sort":_vm.getDataFromApi,"update:items-per-page":_vm.getDataFromApi,"update:page":_vm.getDataFromApi,"update:sort-by":_vm.getDataFromApi,"update:sort-desc":_vm.getDataFromApi},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.creationDate))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }